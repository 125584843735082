import { CONNECTED, DISCONNECTED } from 'pusher-redux'
import { covisageConstants } from '../_constants'
import { cards } from '../assets'

const initialState = {
    selectedCards: [],
    maxCards: 5,
    cards: cards.map(card => ({ ...card, name: `Carta #${card.id}`, flipped: false })),
    cardsFilter: '',
    connected: false,
    boardFlipped: false,
    fourCreativitiesEnabled: false,
    notesVisible: false,
    sabioSelected: false,
    artesanaSelected: false,
    reinaSelected: false,
    aprendizSelected: false,
    sliders: {
      blue: 50,
      green: 50,
      lightBlue: 50,
      pink: 50,
      red: 50,
      yellow: 50,
    },
    rooms: [],
    user: {},
    secondaryBoardEnabled: false,
    secondaryBoardNotes: [],
}

export function covisage(state = initialState, action) {
  switch (action.type) {
    case CONNECTED:
      return {...state, connected: true}

    case DISCONNECTED:
      return {...state, connected: false}

    case covisageConstants.STATE_CHANGED:
      const selectedCards = action.data.selectedCards ? action.data.selectedCards.map(i => {
        const card = cards.find(card => card.id === Number(i.id))
        const selectedCard = state.selectedCards.find(card => card.id === Number(i.id))

        if (selectedCard) {
          if (!i.top) {
            i.top = selectedCard.top
          }

          if (!i.left) {
            i.left = selectedCard.left
          }

          if (!i.transform) {
            i.transform = selectedCard.transform
          }
        }

        return { ...card, ...i}
      }) : state.selectedCards;

      const secondaryBoardNotes = action.data.secondaryBoardNotes ? action.data.secondaryBoardNotes.map(i => {
        const note = state.secondaryBoardNotes.find(note => note.id === i.id)

        if (note) {
          if (!i.top) {
            i.top = note.top
          }

          if (!i.left) {
            i.left = note.left
          }
        }

        return i
      }) : state.secondaryBoardNotes

      return { ...state, ...action.data, selectedCards, secondaryBoardNotes }

    case covisageConstants.SET_CARD_NOTE:
      return { ...state, selectedCards: state.selectedCards.map(i => {
        if (i.id === action.id) {
          i.notes = action.value
        }
        return i
      }) }

    case covisageConstants.SET_SLIDER:
      return { ...state, sliders: { ...state.sliders, [action.color]: action.value } }

    case covisageConstants.SET_ROOMS:
      const rooms = action.rooms.sort((a, b) => (a.created_at < b.created_at) ? -1 : ((a.created_at > b.created_at) ? 1 : 0))

      return { ...state, rooms }

    case covisageConstants.SET_USER:
      return { ...state, user: action.user }

    case covisageConstants.CARDS_SHUFFLED:
      return { ...state, cards: state.cards.sort(() => Math.random() - 0.5) }

    case covisageConstants.DELETE_CARD:
      const cardId = isNaN(action.card) ? action.card.id : action.card

      return { ...state, selectedCards: state.selectedCards.filter(i => i.id !== Number(cardId)) }

    default:
      return { ...state }
  }
}