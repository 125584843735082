import React from 'react'
import ReactSlider from 'react-slider'
import { useSelector } from 'react-redux'

export default ({ onChange, color, text }) => {
  const covisage = useSelector(state => state.covisage)

  const colors = {
    'yellow': '#F9E947',
    'red': '#B32A25',
    'green': '#65A545',
    'blue': '#303B7E',
    'light-blue': '#549FDE',
    'pink': '#CA2B7B',
  }

  return (
      <div className="px-4 py-6 sm:px-12 sm:py-12">
        <div className="bg-c-bg p-2 sm:p-4 rounded-lg">
          <div className="flex items-center flex-wrap">
            <div className={`bg-c-cards-${color} w-8 h-8`} />
            <p className="mx-4 text-3xl font-bold text-center">{ text.title }</p>
          </div>
          <div className="text-md m-2">
              <p className="">{ text.row1 }</p>
              <p className="">{ text.row2 }</p>
              <p className="mt-2">1. <span className="font-bold">{ text.startLabel}:</span> { text.startLabelDescription }</p>
              <p className="mb-2">2. <span className="font-bold">{ text.endLabel }:</span> { text.endLabelDescription }</p>
              <p className="">{ text.description }</p>
          </div>
          <div className="flex w-full justify-between my-4 px-2 sm:px-4 md:px-12 lg:px-24 relative">
            <div className="w-1 h-10 bg-black absolute z-10" style={{top: '0.75rem', right: 'calc(50% - .125rem)'}}></div>
            <div className="w-0 h-0" style={{ borderTop: '2rem solid transparent', borderBottom: '2rem solid transparent', borderRight: `2rem solid ${colors[color]}` }} />
            <ReactSlider
              value={covisage.sliders[color]}
              onChange={(value) => onChange(color, value)}
              className={`py-2 my-4 bg-c-cards-${color} w-full`}
              defaultValue={50}
              thumbClassName="outline-none -mt-3 bg-black h-10 w-10 rounded-full text-transparent"
              trackClassName=""
              renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
            />
            <div className="w-0 h-0" style={{ borderTop: '2rem solid transparent', borderBottom: '2rem solid transparent', borderLeft: `2rem solid ${colors[color]}` }} />
          </div>
          <div className="flex w-full justify-between my-4 px-2 sm:px-4 md:px-12 lg:px-24 break-all">
            <p className="uppercase text-md md:text-xl font-bold mr-1 text-left">{ text.startLabel}</p>
            <p className="uppercase text-md md:text-xl font-bold ml-1 text-right">{ text.endLabel }</p>
          </div>
        </div>
      </div>
  )
}
