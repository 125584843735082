import React, { useState } from 'react'
import clsx from 'clsx'
import { images } from './assets'

export default ({ history }) => {
  const [formData, setFormData] = useState({})
  const [state, setState] = useState({ error: '', message: '', inProgress: false })

  const getPasswordRequest = () => {
    if (!formData.username || state.inProgress) {
      return false
    }

    setState(state => ({ ...state, inProgress: true }))

    const body = JSON.stringify({ username: formData.username })

    return fetch(`${process.env.REACT_APP_BACKEND_URL}?action=password_lost`, { method: 'POST', body }).then((r) => {
      if (r.status === 200) {
        setState(state => ({ ...state, message: 'Email enviado.', error: '' }))
      } else if (r.status === 403) {
        setState(state => ({ ...state, error: 'Intente en un momento, ya se envio un correo.', inProgress: false }))
      } else {
        setState(state => ({ ...state, error: 'El usuario es inválido.', inProgress: false }))
      }
      setTimeout(() => setState(state => ({ ...state, message: '', error: '' })), 5000)
    }).catch((e) => {
      setState(state => ({ ...state, error: 'Lo siento, algo no funcionó bien...', message: '', inProgress: false }))
      setTimeout(() => setState(state => ({ ...state, message: '', error: '' })), 5000)
    })
  }

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value})
  }

  const handlePasswordKeyPress = (e) => {
    if (e.key === 'Enter' && formData.username && !state.inProgress) {
      getPasswordRequest()
    }
  }

  return (
    <div className="coach min-h-screen bg-c-bg flex items-center justify-center">
      <div className="w-full max-w-lg px-4">
        <img alt="instituto8" src={images.logo} className="mx-auto mb-12" />
        <div className="flex flex-wrap justify-evenly mt-12">
          <input name="username" onKeyPress={handlePasswordKeyPress} onChange={handleInput} className="bg-white my-2 focus:outline-none focus:shadow-outline border border-gray-300 py-2 px-4 block w-full appearance-none" type="text" placeholder="Usuario" />

          { state.error && <p className="text-c-cards-red text-sm text-center w-full">{ state.error }</p> }
          { state.message && <p className="text-c-cards-red text-sm text-center w-full">{ state.message }</p> }
          <p className={clsx('border border-c-pink text-c-pink rounded py-2 px-8 mt-2 mr-auto', (!formData.username || state.inProgress) ? 'opacity-25' : 'cursor-pointer')} onClick={getPasswordRequest}>Enviar contraseña por email</p>
          <p onClick={() => history.push('/')} className="text-sm w-full mt-4 cursor-pointer underline">Volver</p>
          <img alt="instituto8" src={images.instituto8} className="w-32 mr-auto mt-8" />
        </div>
      </div>
    </div>
  )
}
