import React, { useState } from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { covisageActions } from './_actions'
import { images } from './assets'

export default ({ history }) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({})
  const [state, setState] = useState({ error: '' })

  const getUserRooms = () => {
    if (!formData.username || !formData.password) {
      return false
    }

    const user = { username: formData.username, password: formData.password }
    const body = JSON.stringify(user)

    return fetch(`${process.env.REACT_APP_BACKEND_URL}?action=get_user_rooms`, { method: 'POST', body }).then((r) => {
      if (r.status !== 200) {
        return Promise.reject(r)
      }
      return r.json()
    }).then((data) => {
      dispatch(covisageActions.setRooms(data.rooms))
      dispatch(covisageActions.setUser(user))
      history.push('/coach')
    }).catch((r) => {
      if (r.status === 403) {
        return setState({ ...state, error: 'El usuario o clave es inválido.'})
      }
      if (r.status === 423) {
        return setState({ ...state, error: 'El usuario ha expirado.'})
      }
      setState({ ...state, error: 'Lo siento, algo no funcionó bien...'})
      setTimeout(() => setState({ ...state, error: ''}), 3000)
    })
  }

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value})
  }

  const handlePasswordKeyPress = (e) => {
    if (e.key === 'Enter' && formData.password) {
      getUserRooms()
    }
  }
  return (
    <div className="coach min-h-screen bg-c-bg flex items-center justify-center">
      <div className="w-full max-w-lg px-4 benton-book">
        <img alt="instituto8" src={images.logo} className="mx-auto mb-12" />
          <p className="text-lg mb-2">
            <span className="benton-bold">Covisage Virtual<span role="img" aria-label="copyright 2022">®️</span></span> es una herramienta visual de 96 foto-cartas que se adquiere con licencia. <a href="https://www.instituto8.org/covisage/" className="underline">Conoce Covisage.</a>
          </p>
          <p className="text-lg mb-2">
            <span className="benton-bold">Si eres parte de la Comunidad</span> de Fotografía Terapéutica solicita tu acceso directo en el apartado Covisage Virtual, <a href="https://cursos.instituto8.org/login" className="underline">accede aquí.</a>
          </p>
          <p className="text-lg">
            <span className="benton-bold">Si todavía no eres parte de la Comunidad</span> de Fotografía terapéutica, <a href="https://www.instituto8.org/comunidad-fotografia-terapeutica" className="underline">únete.</a>
          </p>
        <div className="flex flex-wrap justify-evenly mt-12">
          <input name="username" onChange={handleInput} className="bg-white my-2 focus:outline-none focus:shadow-outline border border-gray-300 py-2 px-4 block w-full appearance-none" type="text" placeholder="usuario" />
          <input name="password" onKeyPress={handlePasswordKeyPress} onChange={handleInput} className="bg-white my-2 focus:outline-none focus:shadow-outline border border-gray-300 py-2 px-4 block w-full appearance-none" type="password" placeholder="*********" />
          { state.error && <p className="text-c-cards-red text-sm text-center w-full">{ state.error }</p> }
          <p className={clsx('border border-c-pink text-c-pink rounded py-2 px-8 mt-2 mr-auto', (!formData.username || !formData.password) ? 'opacity-25' : 'cursor-pointer')} onClick={getUserRooms}>Entrar</p>
          <p onClick={() => history.push('/password_lost')} className="benton-bold text-sm w-full my-4 cursor-pointer">¿Has olvidado tu contraseña?</p>
          <img alt="instituto8" src={images.instituto8} className="w-32 mr-auto mt-8" />
        </div>
      </div>
    </div>
  )
}
