import React from 'react'
import { useParams } from "react-router-dom"

export default () => {
  const { room } = useParams()
  const roomData = JSON.parse(localStorage.getItem(`covisage_room:${room}`)) || {}

  if (!roomData.id || !roomData.password) {
    return <p style={{margin: '5px 10px'}}>Contenido no disponible.</p>
  }

  return <iframe style={{width: '100vw', height: '100vh'}} src={`${process.env.REACT_APP_URL}/instructions/instructions.htm`} title="Covisage"></iframe>
}
