import React, { useCallback, useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import Slider from 'react-input-slider'
import SVG from 'react-inlinesvg'
import { covisageActions } from './_actions'
import { covisageConstants } from './_constants'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { images } from './assets'
import { subscribe, unsubscribe } from 'pusher-redux'
import { useDebouncedCallback } from 'use-debounce'
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import useSize from '@react-hook/size'
import { useStopwatch } from 'react-timer-hook'
import { ZoomIn, ZoomOut } from 'react-feather'
import useHover from '@react-hook/hover'
import ReactHoverObserver from 'react-hover-observer'
import { useOpenInWindow }  from 'use-open-window';
import Moveable from 'react-moveable'
import MythSlider from './components/MythSlider.jsx'
import { Check, X } from 'react-feather'

export const App = ({ history }) => {
  const { room } = useParams()
  const [handleWindowOpen] = useOpenInWindow(`${process.env.REACT_APP_URL}/instructions/${room}`, { specs: { width: '640', height: '768' }, name: 'instructions', titlebar: false, menubar: false, status: false});
  const [roomLocalData, setRoomLocalData] = useState({})
  const [roomLocalState, setRoomLocalState] = useState({
    clockVisible: false,
    cardsSizeSlider: {
      value: 8,
      visible: false
    },
    colorsVisible: false,
    selectedCardsStatus: 'normal', // normal, maximized, minimized, hidden
    notesFontSize: 16,
    loadingMessage: 'Cargando...',
  })
  const covisage = useSelector(state => state.covisage)
  const boardCards = covisage.cards.filter(covisage.cardsFilter ? i => i.colors.primary === covisage.cardsFilter : i => i).filter((card) => !covisage.selectedCards.map(selected => selected.id).includes(card.id))
  const { seconds, minutes, hours, start } = useStopwatch({ autoStart: false })
  const dispatch = useDispatch()
  const selectedCardsContainerRef = useRef()
  // eslint-disable-next-line no-unused-vars
  const [ appWidth, appHeight ] = useSize(selectedCardsContainerRef)
  const secondaryBoardRef = useRef()
  // eslint-disable-next-line no-unused-vars
  const [ secondaryBoardWidth, secondaryBoardHeight ] = useSize(secondaryBoardRef)
  const circleAprendiz = useRef()
  const circleArtesana = useRef()
  const circleReina = useRef()
  const circleSabio = useRef()

  const [ secondaryBoardTarget, setSecondaryBoardTarget ] = useState(null)
  const [ newSecondaryNoteValue, setNewSecondaryNoteValue ] = useState('')

  const hoveringCircleAprendiz = useHover(circleAprendiz, {enterDelay: 200, leaveDelay: 200})
  const hoveringCircleArtesana = useHover(circleArtesana, {enterDelay: 200, leaveDelay: 200})
  const hoveringCircleReina = useHover(circleReina, {enterDelay: 200, leaveDelay: 200})
  const hoveringCircleSabio = useHover(circleSabio, {enterDelay: 200, leaveDelay: 200})

  const cardsMaximized  = covisage.selectedCardsMaximized
  const fourCreativitiesEnabled  = covisage.fourCreativitiesEnabled
  const notesVisible  = covisage.notesVisible

  const pusherAction = useCallback((action, data) => {
    const body = JSON.stringify({ ...data, room, password: roomLocalData.password })

    return fetch(`${process.env.REACT_APP_BACKEND_URL}?action=${action}`, { method: 'POST', body }).then((r) => {
      if (r.status !== 200) {
        return Promise.reject(r)
      }
      return r.json()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    subscribe(`covisage-room-${room}`, 'state-changed', covisageConstants.STATE_CHANGED, {})
    subscribe(`covisage-room-${room}`, 'cards-shuffled', covisageConstants.CARDS_SHUFFLED, {})

    setTimeout(() => pusherAction('ping').then((r) => {
      if (r.expired) {
        return setRoomKey('loadingMessage', 'Esta sala ha expirado.')
      }
      setRoomKey('loadingMessage', null)
    }).catch((r) => {
      if (r.status === 404) {
        return setRoomKey('loadingMessage', 'Esta sala no existe.')
      }
      setRoomKey('loadingMessage', 'No se puede acceder a la sala.')
    }), 3000)

    setRoomLocalData(JSON.parse(localStorage.getItem(`covisage_room:${room}`)) || {})

    start()

    return () => {
      unsubscribe(`covisage-room-${room}`, 'state-changed', covisageConstants.STATE_CHANGED, {})
      unsubscribe(`covisage-room-${room}`, 'cards-shuffled', covisageConstants.CARDS_SHUFFLED, {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [debouncedPusherCallback, /**/ ,callPending] = useDebouncedCallback((action, data) => {
    pusherAction(action, data)
  }, 2500)

  const handleTextAreaInput = (id, e) => {
    dispatch(covisageActions.setCardNote(id, e.target.value))
    debouncedPusherCallback('set_notes', { id, value: e.target.value })
  }

  const handleSliderInput = (color, value) => {
    dispatch(covisageActions.setSlider(color, value))
    debouncedPusherCallback('set_slider', { color, value })
  }

  const handleZoomInClick = () => {
    if (roomLocalState.selectedCardsStatus === 'minimized') {
      return setRoomKey('selectedCardsStatus', 'normal')
    }
    pusherAction('set_room_key', { key: 'selectedCardsMaximized', value: true })
  }

  const selectedCardWidthText = () => {
    const cardsCount = covisage.selectedCards.length

    if (cardsMaximized) {
      if (cardsCount < 7) {
        return `${(appWidth / (cardsCount < 4 ? 5 : cardsCount * 0.75 + 3)) / (fourCreativitiesEnabled ? 2 : 1)}px`
      }
      if (cardsCount < 10) {
        return `${(appWidth / cardsCount) / (fourCreativitiesEnabled ? 2 : 1.25)}px`
      }

      return `${(appWidth / cardsCount) / (fourCreativitiesEnabled ? 2 : 1.25)}px`
    }

    if (roomLocalState.selectedCardsStatus === 'minimized') {
      return '3vh'
    }

    if (roomLocalState.selectedCardsStatus === 'normal') {
      if (cardsCount > 6) {
        return `${(appWidth / cardsCount) * 0.75}px`
      }

      if (cardsCount) {
        return 'calc(50vw / 5)'
      }

      return '10rem'
    }

    return '15vh'
  }

  useEffect(() => {
    dispatch(covisageActions.setSelectedCards(covisage.selectedCards.map( i => {

      i.top = i.top || `${Math.floor(Math.random() * 20) + 40}%`
      i.left = i.left || `${Math.floor(Math.random() * 20) + 40}%`
      i.transform = i.transform || `rotate(${Math.floor(Math.random() * 20) - 10}deg)`

      return i
    })))

    dispatch(covisageActions.setSecondaryBoardNotes(covisage.secondaryBoardNotes.map( i => {

      i.top = i.top || `${Math.floor(Math.random() * 20) + 40}%`
      i.left = i.left || `${Math.floor(Math.random() * 20) + 40}%`

      return i
    })))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [covisage.secondaryBoardEnabled])

  const cardBackgroundStyle =  (i) => { return {
    backgroundColor: '#fff',
    backgroundImage: `url(${covisage.boardFlipped ? i.back : i.image})`,
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: `${roomLocalState.cardsSizeSlider.value / 30}rem`,
    height: `${roomLocalState.cardsSizeSlider.value}rem`,
    margin: `${roomLocalState.cardsSizeSlider.value / 50}rem`,
    width: `${roomLocalState.cardsSizeSlider.value / 2}rem`,
  }}

  const secondaryBoardCardWidth = Math.floor(secondaryBoardWidth / 18)

  const secondaryBoardStyle = useCallback(i => {
    return {
      aspectRatio: '16/9',
      backgroundImage: `url(${images.tapete01})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    }
  }, [secondaryBoardCardWidth])

  const secondaryBoardCardStyle = useCallback(i => {
    const width = `${secondaryBoardCardWidth}px`

    return {
      backgroundColor: '#fff',
      backgroundImage: `url(${i.flipped ? i.back : i.image})`,
      backgroundPositionX: 'center',
      backgroundPositionY: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: `calc(${width} / 15)`,
      margin: 0,
      height: `calc(${width} * 2)`,
      width,
      top: i.top || '5px',
      left: i.left || '5px',
      transform: i.transform || 'rotate(0deg)',
    }
  }, [secondaryBoardCardWidth])

  const secondaryBoardNoteStyle = useCallback((i) => {
    return {
      top: i.top || '5px',
      left: i.left || '5px',
      transform: i.transform || 'rotate(0deg)',
      maxWidth: `${secondaryBoardCardWidth * 3}px`,
      padding: `${secondaryBoardCardWidth / 16}px ${secondaryBoardCardWidth / 4}px`,
      fontSize: `${secondaryBoardCardWidth / 4}px`,
      fontWeight: 500,
      opacity: 1,
      color: '#000',
      borderRadius: `${secondaryBoardCardWidth / 20}px`,
      zIndex: 5,
      backgroundColor: '#d8d8d8ee',
    }
  }, [secondaryBoardCardWidth])

  const selectedCardBackgroundStyle =  (i) => {
    const style = {}

    if (fourCreativitiesEnabled && cardsMaximized) {
      if (
        (i.type !== 'aprendiz' && hoveringCircleAprendiz) ||
        (i.type !== 'sabio' && hoveringCircleSabio) ||
        (i.type !== 'reina' && hoveringCircleReina) ||
        (i.type !== 'artesana' && hoveringCircleArtesana)
      ) {
          style.backgroundImage = `linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%,rgba(255, 255, 255, 0.8) 100%), url(${i.flipped ? i.back : i.image})`
      }
    }

    if (i.flipped) {
      style.border = '1px solid'
    }

    return { ...{
      backgroundColor: '#fff',
      backgroundImage: `url(${i.flipped ? i.back : i.image})`,
      backgroundPositionX: 'center',
      backgroundPositionY: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: `calc(${selectedCardWidthText()} / 15)`,
      margin: `calc(${selectedCardWidthText()} / 15)`,
      height: `calc(${selectedCardWidthText()} * 2)`,
      width: selectedCardWidthText(),
    }, ...style }
  }

  const getItemStyleSelectedCards = (snapshot, draggableStyle) => {
    if (!snapshot.isDragging) {
      return draggableStyle
    }

    if (snapshot.isDropAnimating) {
      return { ...draggableStyle, transitionDuration: `0.001s` }
    }

    return { ...draggableStyle, opacity: .75 }
  }

  const selectedCardsContainerStyle = () => {
    const backgroundImages = []

    if (cardsMaximized) {
      if (fourCreativitiesEnabled) {
        backgroundImages.push({url: images.fourCreativitiesArrows, positionX: 'center', positionY: 'center', size: '32%'})
      }
      if (hoveringCircleSabio || covisage.sabioSelected) {
       backgroundImages.push({url: images.bgSabio, positionX: 'left', positionY: 'top', size: '30%'})
      }
      if (hoveringCircleArtesana || covisage.artesanaSelected) {
       backgroundImages.push({url: images.bgArtesana, positionX: 'right', positionY: 'top', size: '30%'})
      }
      if (hoveringCircleReina || covisage.reinaSelected) {
       backgroundImages.push({url: images.bgReina, positionX: 'left', positionY: 'bottom', size: '30%'})
      }
      if (hoveringCircleAprendiz || covisage.aprendizSelected) {
       backgroundImages.push({url: images.bgAprendiz, positionX: 'right', positionY: 'bottom', size: '30%'})
      }
    }

    return {
      alignItems: cardsMaximized ? 'center' : 'end',
      backgroundImage: backgroundImages.map(i => `url(${i.url})`).join(', '),
      backgroundPositionX: backgroundImages.map(i => i.positionX).join(', '),
      backgroundPositionY: backgroundImages.map(i => i.positionY).join(', '),
      backgroundRepeat: 'no-repeat',
      backgroundSize: backgroundImages.map(i => i.size).join(', '),
      bottom: cardsMaximized ? 0 : '1rem',
      display: 'flex',
      height: cardsMaximized ? 'calc(100% - 2rem)' : null,
      justifyContent: 'center',
      margin: cardsMaximized ? '1rem' : null,
      left: '5rem',
      pointerEvents: 'none',
      position: 'fixed',
      width: cardsMaximized ? 'calc(100% - 7rem)' : 'calc(100% - 5rem)',
    }
  }

  const onBeforeCapture = (result) => {}

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (result.source.droppableId === 'selected-cards') {
      if (!result.destination || result.destination.droppableId === 'cards') {
        dispatch(covisageActions.deleteCard(result.draggableId))
        pusherAction('delete_card', { id: Number(result.draggableId) })
      } else {
        const items = reorder(covisage.selectedCards, result.source.index, result.destination.index)
        dispatch(covisageActions.setSelectedCards(items))
        pusherAction('set_cards_order', { order: items.map(i => i.id) })
      }
    }
  }

  const flipCard = (event, item) => {
    if (!event.defaultPrevented && event.target.type !== 'textarea') {
      pusherAction('flip_card', { id: item.id })
    }
  }

  const setRoomKey = (key, value) => {
    setRoomLocalState((state) => { return {...state, [key]: value } })
    return true
  }

  const minimizeSelectedCards = () => {
    return pusherAction('set_room_key', { key: 'selectedCardsMaximized', value: false })
  }

  const toggleFourCreativities = () => {
    pusherAction('set_room', { data: {
      selectedCardsMaximized: true,
      notesVisible: false,
      fourCreativitiesEnabled: !fourCreativitiesEnabled
    }})
  }

  const gotoCardsScreen = () => {
    pusherAction('set_room', { data: {
      selectedCardsMaximized: false,
      fourCreativitiesEnabled: false,
      cardsFilter: '',
      sabioSelected: false,
      artesanaSelected: false,
      reinaSelected: false,
      aprendizSelected: false,
    }})
  }

  const toggleFilter = (color) => {
    pusherAction('set_room_key', { key: 'cardsFilter', value: covisage.cardsFilter === color ? '' : color }).then(() => {
      setRoomKey('colorsVisible', false)
      if (cardsMaximized) {
        minimizeSelectedCards().then(() => {
          pusherAction('set_room_key', { key: 'fourCreativitiesEnabled', value: false })
        })
      }
    })
  }

  const addSecondaryNote = () => {
    if (!newSecondaryNoteValue) {
      return false
    }
    pusherAction('add_secondary_board_note', { value: newSecondaryNoteValue })
    setNewSecondaryNoteValue('')
  }

  return <>
    { roomLocalState.loadingMessage && <p className="mx-3 my-2">{ roomLocalState.loadingMessage }</p> }

    <div className={clsx('app', roomLocalState.loadingMessage && 'hidden')}>
      <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>

        <div ref={selectedCardsContainerRef}>
          { !covisage.secondaryBoardEnabled &&
            <div style={selectedCardsContainerStyle()}>
              <Droppable droppableId="selected-cards" direction="horizontal">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} className={clsx('relative flex rounded-md', (covisage.selectedCards.length > 6) && cardsMaximized && 'flex-wrap content-center justify-center', cardsMaximized ? 'bg-c-bg-90 p-0' : 'bg-c-black-90 pt-2 pb-2 pl-2 sm:pl-12 pr-0 sm:pr-2')} {...provided.droppableProps}>
                    <div ref={circleSabio} onClick={() => pusherAction('set_room_key', { key: 'sabioSelected', value: !covisage.sabioSelected })} className="absolute pointer-events-auto" style={{width: `calc(${selectedCardWidthText()} / 1.5)`, top: `calc(-${selectedCardWidthText()} * 1.2)`, left: covisage.selectedCards.length < 2 ? '-5rem' : '-2rem' }}>
                      { fourCreativitiesEnabled && cardsMaximized && <SVG src={images.circleSabio} /> }
                    </div>
                    <div ref={circleArtesana} onClick={() => pusherAction('set_room_key', { key: 'artesanaSelected', value: !covisage.artesanaSelected })} className="absolute pointer-events-auto" style={{width: `calc(${selectedCardWidthText()} / 1.5)`, top: `calc(-${selectedCardWidthText()} * 1.2)`, right: covisage.selectedCards.length < 2 ? '-5rem' : '-2rem' }}>
                      { fourCreativitiesEnabled && cardsMaximized && <SVG src={images.circleArtesana} /> }
                    </div>
                    <div ref={circleReina} onClick={() => pusherAction('set_room_key', { key: 'reinaSelected', value: !covisage.reinaSelected })} className="absolute pointer-events-auto" style={{width: `calc(${selectedCardWidthText()} / 1.5)`, bottom: `calc(-${selectedCardWidthText()} * 1.2)`, left: covisage.selectedCards.length < 2 ? '-5rem' : '-2rem' }}>
                      { fourCreativitiesEnabled && cardsMaximized && <SVG src={images.circleReina} /> }
                    </div>
                    <div ref={circleAprendiz} onClick={() => pusherAction('set_room_key', { key: 'aprendizSelected', value: !covisage.aprendizSelected })} className="absolute pointer-events-auto" style={{width: `calc(${selectedCardWidthText()} / 1.5)`, bottom: `calc(-${selectedCardWidthText()} * 1.2)`, right: covisage.selectedCards.length < 2 ? '-5rem' : '-2rem' }}>
                      { fourCreativitiesEnabled && cardsMaximized && <SVG src={images.circleAprendiz} /> }
                    </div>
                      { covisage.selectedCards.map((i, index) => { return <div key={i.id} onClick={(e) => flipCard(e, i)}>
                        <Draggable isDragDisabled={(covisage.selectedCards.length > 14) && cardsMaximized} draggableId={`${i.id}`} index={index}>
                          {(provided, snapshot) => (
                            <div className="text-center" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyleSelectedCards(snapshot, provided.draggableProps.style)}>
                              <div className="pointer-events-auto h-full w-full" style={selectedCardBackgroundStyle(i)}></div>
                              { cardsMaximized && notesVisible && <div className="mb-4">
                                <textarea
                                  className="pointer-events-auto bg-transparent px-4 py-2"
                                  value={i.notes}
                                  onFocus={() => callPending()}
                                  maxLength="2048"
                                  disabled={false}
                                  onChange={(e) => handleTextAreaInput(i.id, e)}
                                  placeholder="Notas..."
                                  style={{
                                    backgroundSize: '100% 100%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${images.postItBackground})`,
                                    fontSize: `${roomLocalState.notesFontSize}px`,
                                    resize: 'none',
                                    width: `calc(${selectedCardWidthText()} * .95)`,
                                    height: `calc(${selectedCardWidthText()} * .95)`,
                                    overflow: 'visible',
                                  }}
                                />
                              </div> }
                            </div>
                          )}
                        </Draggable>
                      </div>}) }
                      { covisage.selectedCards.length === 0 && <>
                        { [0].map(i => { return (
                          <div key={i} className="break-all my-2 mx-1 sm:my-4 sm:mx-2 md:my-6 md:mx-3">
                            <div className={clsx('h-full w-full border rounded-lg flex justify-center items-center opacity-50', cardsMaximized && 'border-c-black-90')} style={{ height: `calc(${selectedCardWidthText()} * 2)`, width: selectedCardWidthText() }}></div>
                          </div>
                        )}) }
                      </> }
                      { (!cardsMaximized || (roomLocalState.selectedCardsStatus !== 'minimized' && !fourCreativitiesEnabled)) && <div className="m-2">
                        { !cardsMaximized && <ZoomIn onClick={handleZoomInClick} className={clsx('w-8 h-8 text-white pointer-events-auto', false && 'opacity-25')} /> }
                        { roomLocalState.selectedCardsStatus !== 'minimized' && !fourCreativitiesEnabled && <ZoomOut onClick={() => cardsMaximized ? minimizeSelectedCards() : setRoomKey('selectedCardsStatus', 'minimized')} className={clsx('w-8 h-8 pointer-events-auto', cardsMaximized ? 'text-black opacity-25 hover:opacity-100' : 'text-white')} /> }
                        { covisage.selectedCards.length > 0 && roomLocalState.selectedCardsStatus !== 'minimized' && !cardsMaximized && <SVG src={images.turnAround} onClick={() => pusherAction('flip_selected_cards', {})} className="w-full pointer-events-auto h-5 mx-auto mt-3 cursor-pointer fill-current text-white" /> }
                      </div> }
                      { provided.placeholder }
                  </div>
                )}
              </Droppable>
            </div>
          }
        </div>

      </DragDropContext>
      <div className="sidebar w-20 h-full fixed bg-c-bg1 flex flex-wrap content-between">
        <div className="w-full">
          <img alt="logo" src={images.logoVertical} className="px-4 pt-16 z-10" />
        </div>
        <div className="w-full flex flex-wrap">
          { covisage.selectedCards.length > 0 && <SVG src={images.iconTapete} onClick={() => pusherAction('toggle_secondary_board', {})} className="w-full h-6 mx-auto my-3 cursor-pointer opacity-25 hover:opacity-75" /> }

          { !covisage.secondaryBoardEnabled && (cardsMaximized || covisage.cardsFilter) && <SVG src={images.cardsScreen} onClick={gotoCardsScreen} className="w-full h-8 mx-auto my-3 cursor-pointer opacity-25 hover:opacity-75" /> }

          { !covisage.secondaryBoardEnabled && !cardsMaximized && <>
            <ReactHoverObserver className="w-full">
              { ({ isHovering }) => <>
                <div className="relative my-3 w-full">
                  <SVG src={images.cardSize} onClick={() =>  setRoomLocalState((state) => { return { ...state, cardsSizeSlider: { ...state.cardsSizeSlider, visible: !state.cardsSizeSlider.visible }} })} className={clsx('h-10 mx-auto cursor-pointer hover:opacity-75', roomLocalState.cardsSizeSlider.visible || isHovering ? 'opacity-50' : 'opacity-25')} />
                  { (roomLocalState.cardsSizeSlider.visible || isHovering) && <div className="absolute top-0 h-full w-64 pl-4 rounded-md" style={{left: '100%'}}>
                    <div className="bg-white h-full w-full rounded-md flex items-center justify-center px-4">
                      <Slider xstep={0.05} xmin={7} xmax={50} axis="x" x={roomLocalState.cardsSizeSlider.value} onChange={({ x }) => setRoomLocalState((state) => { return { ...state, cardsSizeSlider: { ...state.cardsSizeSlider, value: x }} })} styles={{ track: { backgroundColor: '#ccc' }, active: { backgroundColor: '#ccc' }}} />
                    </div>
                  </div> }
                </div>
              </> }
            </ReactHoverObserver>
          </> }

          { !covisage.secondaryBoardEnabled && !cardsMaximized && <SVG src={images.shuffleCards} onClick={() => pusherAction('shuffle_cards', {})} className="w-full h-6 mx-auto my-3 cursor-pointer opacity-25 hover:opacity-75" /> }
          { !covisage.secondaryBoardEnabled && !cardsMaximized && <SVG src={images.turnAround} onClick={() => pusherAction('flip_board_cards', {})} className="w-full h-6 mx-auto my-3 cursor-pointer opacity-25 hover:opacity-75" /> }

          { !covisage.secondaryBoardEnabled && !fourCreativitiesEnabled && roomLocalData.id && <>
            <ReactHoverObserver className="w-full">
              { ({ isHovering }) => <>
                <div className="my-3 relative w-full">
                  <SVG src={images.showTime} onClick={() => setRoomKey('clockVisible', !roomLocalState.clockVisible)} className={clsx('h-8 mx-auto cursor-pointer hover:opacity-75', roomLocalState.clockVisible || isHovering ? 'opacity-50' : 'opacity-25')} />
                  { (roomLocalState.clockVisible || isHovering) && <div className="bg-black absolute top-0 h-full w-64 flex items-center justify-center ml-8 bg-white rounded-md" style={{left: '100%'}}>
                    <p className="text-xl font-medium mx-2 my-4 rounded border border-white text-center">{`${hours}`.padStart(2, 0)}:{`${minutes}`.padStart(2, 0)}:{`${seconds}`.padStart(2, 0)}</p>
                  </div> }
                </div>
              </> }
            </ReactHoverObserver>
          </> }

          { !covisage.secondaryBoardEnabled && cardsMaximized && <SVG src={images.showNotes} onClick={() => pusherAction('set_room_key', { key: 'notesVisible', value: !notesVisible})} className="w-full h-6 mx-auto my-3 cursor-pointer opacity-25 hover:opacity-75" /> }

          { !covisage.secondaryBoardEnabled &&
            <ReactHoverObserver className="w-full relative">
              { ({ isHovering }) => <>
                <SVG src={images.showColors} onClick={() => pusherAction('set_room_key', { key: 'cardsFilter', value: '' })} className={clsx('w-full h-8 mx-auto my-3 cursor-pointer hover:opacity-75 fill-current', covisage.cardsFilter || roomLocalState.colorsVisible ? 'opacity-50' : 'opacity-25')} />

                { (roomLocalState.colorsVisible || isHovering) && <div style={{ left: '5rem' }} className="absolute bottom-0 pb-16 w-full">
                  <ReactHoverObserver>
                    { ({ isHovering }) => <>
                      <div onClick={() => toggleFilter('yellow')} className="bg-c-cards-yellow mx-2 h-6 my-1">
                        { isHovering && <div className="relative bg-black opacity-100 text-white shadow-md w-32 text-center ml-1" style={{left: '100%'}}>
                          <p className="">Ley Universal</p>
                        </div> }
                      </div>
                    </> }
                  </ReactHoverObserver>
                  <ReactHoverObserver>
                    { ({ isHovering }) => <>
                      <div onClick={() => toggleFilter('red')} className="bg-c-cards-red mx-2 h-6 my-1">
                        { isHovering && <div className="relative bg-black opacity-100 text-white w-32 text-center ml-1" style={{left: '100%'}}>
                          <p className="">Lo Oculto</p>
                        </div> }
                      </div>
                    </> }
                  </ReactHoverObserver>
                  <ReactHoverObserver>
                    { ({ isHovering }) => <>
                      <div onClick={() => toggleFilter('green')} className="bg-c-cards-green mx-2 h-6 my-1">
                        { isHovering && <div className="relative bg-black opacity-100 text-white w-32 text-center ml-1" style={{left: '100%'}}>
                          <p className="">La Deuda</p>
                        </div> }
                      </div>
                    </> }
                  </ReactHoverObserver>
                  <ReactHoverObserver>
                    { ({ isHovering }) => <>
                      <div onClick={() => toggleFilter('blue')} className="bg-c-cards-blue mx-2 h-6 my-1">
                        { isHovering && <div className="relative bg-black opacity-100 text-white w-32 text-center ml-1" style={{left: '100%'}}>
                          <p className="">El Pasado</p>
                        </div> }
                      </div>
                    </> }
                  </ReactHoverObserver>
                  <ReactHoverObserver>
                    { ({ isHovering }) => <>
                      <div onClick={() => toggleFilter('light-blue')} className="bg-c-cards-light-blue mx-2 h-6 my-1">
                        { isHovering && <div className="relative bg-black opacity-100 text-white w-32 text-center ml-1" style={{left: '100%'}}>
                          <p className="">El Ser</p>
                        </div> }
                      </div>
                    </> }
                  </ReactHoverObserver>
                  <ReactHoverObserver>
                    { ({ isHovering }) => <>
                      <div onClick={() => toggleFilter('pink')} className="bg-c-cards-pink mx-2 h-6 my-1">
                        { isHovering && <div className="relative bg-black opacity-100 text-white w-32 text-center ml-1" style={{left: '100%'}}>
                          <p className="">Lo Auténtico</p>
                        </div> }
                      </div>
                    </> }
                  </ReactHoverObserver>
                </div> }
              </> }
            </ReactHoverObserver>
          }

          { !covisage.secondaryBoardEnabled && !fourCreativitiesEnabled && <SVG src={images.fourCreativities} onClick={toggleFourCreativities} className="w-full h-6 mx-auto my-3 cursor-pointer opacity-25 hover:opacity-75" /> }

          { roomLocalData.id && <SVG src={images.question} onClick={handleWindowOpen} className="w-full h-6 mx-auto my-3 cursor-pointer opacity-25 hover:opacity-75" /> }
        </div>
      </div>

      <div className={clsx('desktop ml-20 min-h-screen', (covisage.cardsFilter || covisage.boardFlipped || covisage.secondaryBoardEnabled) && !cardsMaximized ? 'bg-c-black-90' : 'bg-c-bg')}>
        { !covisage.secondaryBoardEnabled && !cardsMaximized && covisage.cardsFilter === 'yellow' && <MythSlider color='yellow' onChange={(color, value) => handleSliderInput(color, value)} text={{
          title: 'Mito de la ley universal',
          row1: 'Sirve para ver cómo te sientes ante los hábitos o los patrones de tu entorno sociocultural. Se sustenta en la aceptación cultural.',
          row2: 'El mito de la ley universal tiene dos extremos:',
          startLabel: 'Normalizar',
          startLabelDescription: 'se centra en una visión mecánica del mundo y busca crear patrones estables.',
          endLabel: 'Aventurar',
          endLabelDescription: 'se centra en una visión orgánica del mundo. Se parte de que no existen leyes universales.',
          description: 'La clave para su interpretación es ponderar su valor en cada ámbito. Este mito ayuda a centrar la macrovisión del mundo. Se trata de revisar los límites de las leyes con las que se mide el mundo.',
        }} /> }

        { !covisage.secondaryBoardEnabled && !cardsMaximized && covisage.cardsFilter === 'light-blue' && <MythSlider color='light-blue' onChange={(color, value) => handleSliderInput(color, value)} text={{
          title: 'Mito del ser',
          row1: 'Sirve para ver la actitud con la que afrontas los retos. Se sustenta en la responsabilidad.',
          row2: 'El mito del ser tiene dos extremos:',
          startLabel: 'Pasividad',
          startLabelDescription: 'pensar que lo que te ocurre tiene una causa externa que te convierte en un sujeto pasivo; tú solo puedes dejar que suceda.',
          endLabel: 'Hiperresponsabilidad',
          endLabelDescription: 'pensar que lo que te ocurre es responsabilidad tuya.',
          description: 'La clave para su interpretación es actualizar su valor. El ser es un predicado: ser valiente, ser amable, ser sofiscticado, etcétera. El ser es un mito que articula una relación de una conciencia con algo.',
        }} /> }

        { !covisage.secondaryBoardEnabled && !cardsMaximized && covisage.cardsFilter === 'pink' && <MythSlider color='pink' onChange={(color, value) => handleSliderInput(color, value)} text={{
          title: 'Mito de lo auténtico',
          row1: 'Sirve para ver cómo te relacionas con lo verdadero y lo falso. Se sustenta en la veracidad.',
          row2: 'El mito de lo auténtico tiene dos extremos:',
          startLabel: 'Escepticismo',
          startLabelDescription: 'cuestionar cualquier idea que pueda justificar un conocimiento como verdadero o auténtico.',
          endLabel: 'Justificación',
          endLabelDescription: 'se legitima lo que se piensa como verdadero en cualquier caso.',
          description: 'La clave para su interpretación es contextualizar su valor. Lo auténtico es un significado y, así, una convención social. De este modo, se trata de comprender las reactuaciones de convenciones culturales: aquellas con las que nos legitimamos, tanto para justificar como para rechazar.',
        }} /> }

        { !covisage.secondaryBoardEnabled && !cardsMaximized && covisage.cardsFilter === 'blue' && <MythSlider color='blue' onChange={(color, value) => handleSliderInput(color, value)} text={{
          title: 'Mito del pasado',
          row1: 'Sirve para ver cómo gestionas tus anclajes emocionales. Se sustenta en la memoria.',
          row2: 'El mito del pasado tiene dos extremos:',
          startLabel: 'Apego',
          startLabelDescription: 'lo que pasa ahora es efecto de lo que ha pasado antes.',
          endLabel: 'Desapego',
          endLabelDescription: 'lo que pasó no es importante en el presente.',
          description: 'La clave para su interpretación es mesurar su peso. El mito del pasado ayuda a tomar decisiones en el presente, determinadas por el peso de los apegos.',
        }} /> }

        { !covisage.secondaryBoardEnabled && !cardsMaximized && covisage.cardsFilter === 'red' && <MythSlider color='red' onChange={(color, value) => handleSliderInput(color, value)} text={{
          title: 'Mito de lo oculto',
          row1: 'Sirve para ver cómo gestionas tus anclajes emocionales. Se sustenta en la memoria.',
          row2: 'El mito del pasado tiene dos extremos:',
          startLabel: 'Impotencia',
          startLabelDescription: 'hay tanto que es imposible verlo todo.',
          endLabel: 'Delirio',
          endLabelDescription: 'imaginar sin entrar en lo que permanece oculto y darlo por real; la fantasía delirante como estrategia para no adentrarse en lo oculto. La clave para su interpretación es actualizar su valor. La incertidumbre implica la posibilidad de la certidumbre, que es la oportunidad de desvelar aquello que tiene lugar',
          description: 'La clave para su interpretación es actualizar su valor. La incertidumbre implica la posibilidad de la certidumbre, que es la oportunidad de desvelar aquello que tiene lugar.',
        }} /> }

        { !covisage.secondaryBoardEnabled && !cardsMaximized && covisage.cardsFilter === 'green' && <MythSlider color='green' onChange={(color, value) => handleSliderInput(color, value)} text={{
          title: 'Mito de la deuda',
          row1: 'Sirve para ver cómo enfocas el origen de tus derechos y tus deberes. Se sustenta en la individualidad.',
          row2: 'El mito de la deuda tiene dos extremos:',
          startLabel: 'Herencia',
          startLabelDescription: 'nacemos en deuda perpetua con el mundo, con la patria, con Dios, con los padres, con el capital, etcétera.',
          endLabel: 'Préstamo',
          endLabelDescription: 'nacemos en mundo sin dueño, prestado, como individuos autosuficientes.',
          description: 'La clave para su interpretación es contextualizar su valor actual. La deuda es el mito que nos ayuda a entender y gestionar los derechos y los deberes individuales y colectivos.',
        }} /> }

        { !cardsMaximized && !covisage.secondaryBoardEnabled && <div className="cards flex flex-wrap justify-center content-center">
          { boardCards.map((card) => { return (
            <div key={card.id} onClick={() => setRoomKey('selectedCardsStatus', 'normal') && pusherAction('add_card', { id: card.id }).catch((e) => console.log(e))} className={clsx('bg-white flex shadow-md items-center')} style={cardBackgroundStyle(card)}>
              <p className="mx-auto text-white opacity-75 hidden">{card.name}</p>
            </div>
          )}) }
        </div> }

        { !cardsMaximized && !covisage.secondaryBoardEnabled && <div style={{height: `calc(${selectedCardWidthText()} * 2 + 6rem)`}} /> }

        <div ref={secondaryBoardRef}>
          { covisage.secondaryBoardEnabled &&
            <div className="flex min-h-screen" onClick={(e) => e.target.id === 'secondary-board-cards-container' && setSecondaryBoardTarget(null) }>
              <Moveable
                //translateZ={50}
                bounds={{ left: 50, right: 200, top: 50, bottom: 200 }}
                target={secondaryBoardTarget}
                container={null}
                origin={false}
                edge={false}
                draggable={true}
                throttleDrag={0}
                //onDragStart={e => {
                //    //e.set(frame.translate);
                //}}
                //onDragStart={({ target, clientX, clientY }) => {
                //    console.log("onDragStart", target);
                //}}
                //onDrag={e => {
                //    setFrame({ translate: e.beforeTranslate })
                //    e.target.style.transform = `translate(${e.beforeTranslate[0]}px, ${e.beforeTranslate[1]}px)`;
                //}}
                onDrag={({
                    target,
                    beforeDelta, beforeDist,
                    left, top,
                    right, bottom,
                    delta, dist,
                    transform,
                    clientX, clientY,
                }) => {
                    if (Number(target.id)) {
                      if (left < 5 || left > (secondaryBoardWidth - secondaryBoardCardWidth - 5) || top < 5 || top > (secondaryBoardWidth * 9 / 16) - (2 * secondaryBoardCardWidth + 5)) {
                        return false
                      }
                    } else {
                      if (left < 15 || left > (secondaryBoardWidth - target.offsetWidth - 15) || top < 15 || top > (secondaryBoardWidth * 9 / 16) - target.offsetHeight - 15) {
                        return false
                      }
                    }
                    target.style.left = `${ left * 100 / secondaryBoardWidth }%`;
                    target.style.top = `${top * 100 / (secondaryBoardWidth * 9 / 16)}%`;
                    //console.log("onDrag translate", dist);
                    //target.style.transform = transform;
                }}
                onDragEnd={({ target, isDrag, clientX, clientY }) => {
                    const data = {
                      id: target.id,
                      left: target.style.left,
                      top: target.style.top,
                      transform: target.style.transform,
                    }
                    if (Number(target.id)) {
                      return pusherAction('secondary_board_card_updated', data)
                    }
                    pusherAction('update_secondary_board_note', data)
                }}
                keepRatio={true}
                resizable={false}
                scalable={false}
                rotatable={true}
                throttleRotate={0}
                //onRotateStart={({ target, clientX, clientY }) => {
                //    console.log("onRotateStart", target);
                //}}
                onRotate={({
                    target,
                    delta, dist,
                    transform,
                    clientX, clientY,
                }) => {
                    target.style.transform = transform;
                }}
                onRotateEnd={({ target, isDrag, clientX, clientY }) => {
                    const data = {
                      id: target.id,
                      left: target.style.left,
                      top: target.style.top,
                      transform: target.style.transform,
                    }
                    if (Number(target.id)) {
                      pusherAction('secondary_board_card_updated', data)
                    }
                    pusherAction('update_secondary_board_note', data)
                }}
                warpable={false}
                pinchable={false}
              />
              <div style={secondaryBoardStyle()} className="w-full opacity-80 m-auto relative" id="secondary-board-cards-container">
                { covisage.secondaryBoardNotes.map((i, index) => (
                  <span key={i.id} onClick={(e) => setSecondaryBoardTarget(e.target)}>
                    <div id={i.id} className={`flex items-center justify-center absolute break-all`} style={secondaryBoardNoteStyle(i)}>{ i.value }</div>
                  </span>
                )) }
                { covisage.selectedCards.map((i, index) => (
                  <span key={i.id} onClick={(e) => setSecondaryBoardTarget(e.target)}>
                    <div className={`h-full w-full absolute`} id={i.id} style={secondaryBoardCardStyle(i)}></div>
                  </span>
                )) }
              </div>
              <div className="bg-white absolute bottom-0 flex px-2 py-1">
                <input value={newSecondaryNoteValue} onChange={({ target }) => setNewSecondaryNoteValue(target.value)} name="notes" minLength="3" placeholder="Nueva nota..." className="px-1 mr-2" />
                <Check onClick={addSecondaryNote} className="w-6 h-6 text-green-500 cursor-pointer my-auto" />
                { secondaryBoardTarget && !Number(secondaryBoardTarget.id) && <X onClick={() => pusherAction('delete_secondary_board_note', { id: secondaryBoardTarget.id }) && setSecondaryBoardTarget(null) } className="w-6 h-6 text-red-500 cursor-pointer my-auto" /> }
              </div>
            </div>
          }
        </div>

      </div>
    </div>
  </>
}

export default App
