import React from 'react';
import ReactDOM from 'react-dom';
import './internal.scss';
import Admin from './Admin.jsx';
import Coach from './Coach.jsx';
import Game from './Game.jsx';
import Main from './Main.jsx';
import PasswordLost from './PasswordLost';
import Instructions from './components/Instructions.jsx';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { history, store } from './_helpers';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/coach" component={Coach} />
          <Route exact path="/jornadas2020" component={Coach} />
          <Route exact path="/password_lost" component={PasswordLost} />
          <Route exact path="/admin" component={Admin} />
          <Route path="/instructions/:room" component={Instructions} />
          <Route path="/:room" component={Game} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

