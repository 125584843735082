import React, { useState, useEffect } from 'react'
import moment from 'moment'
import clsx from 'clsx'
import 'moment/locale/es'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector, useDispatch } from 'react-redux'
import { covisageActions } from './_actions'
import { images } from './assets'

export default ({ history }) => {
  const dispatch = useDispatch()
  const rooms = useSelector(state => state.covisage.rooms)
  const [lastRoom, setLastRoom] = useState({})
  const [lastCopy, setLastCopy] = useState('')
  const user = useSelector(state => state.covisage.user)
  const [formData, setFormData] = useState({
    expires_at: 3,
    max_cards: 14,
    room_name: '',
  })

  useEffect(() => {
    if (history.location.pathname === '/jornadas2020') {
      const user = { username: 'jornadas2020', password: 'jornadas2020' }
      const body = JSON.stringify(user)

      fetch(`${process.env.REACT_APP_BACKEND_URL}?action=get_user_rooms`, { method: 'POST', body }).then((r) => {
        if (r.status !== 200) {
          return Promise.reject(r)
        }
        return r.json()
      }).then((data) => {
        dispatch(covisageActions.setRooms(data.rooms))
        dispatch(covisageActions.setUser(user))
      }).catch((r) => {
        history.push('/')
      })
    } else if (!user.username || !user.password) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [state, setState] = useState({ error: '' })

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value})
    setLastRoom({})
  }

  const createRoom = () => {
    const body = JSON.stringify({
      username: user.username,
      password: user.password,
      max_cards: formData.max_cards,
      name: formData.room_name,
      expires_at: moment().add(formData.expires_at, 'days').add(2, 'hours').toISOString(),
    })

    return fetch(`${process.env.REACT_APP_BACKEND_URL}?action=create`, { method: 'POST', body }).then((r) => {
      if (r.status !== 201) {
        return Promise.reject(r)
      }
      return r.json()
    }).then((data) => {
      setFormData({ ...formData, room_name: ''})
      localStorage.setItem(`covisage_room:${data.id}`, JSON.stringify(data))
      setLastRoom(data)
      dispatch(covisageActions.setRooms([ ...rooms, data]))
    }).catch((r) => {
      setState({ ...state, error: 'No se puede crear la sala...'})
      setTimeout(() => setState({ ...state, error: ''}), 3000)
    })
  }

  const deleteRoom = (room) => {
    const body = JSON.stringify({
      username: user.username,
      password: user.password,
      room: room.id,
    })

    return fetch(`${process.env.REACT_APP_BACKEND_URL}?action=delete_room`, { method: 'POST', body }).then((r) => {
      if (r.status !== 200) {
        return Promise.reject(r)
      }
      return r.json()
    }).then((data) => {
      dispatch(covisageActions.setRooms(data.rooms))
    }).catch((r) => {
      setState({ ...state, error: 'No se puede borrar la sala...'})
      setTimeout(() => setState({ ...state, error: ''}), 3000)
    })
  }

  const handleCreateKeyPress = (e) => {
    if (e.key === 'Enter' && formData.room_name) {
      createRoom()
    }
  }

  return (
    <div className="coach min-h-screen bg-c-bg flex items-center justify-center">
      <div className="w-full px-4">
        <div className="max-w-lg mx-auto my-4">
          <img alt="Instituto 8"src={images.logo} className="mx-auto px-4" onClick={() => history.push('/')}/>
        </div>
        <div className="max-w-2xl bg-white mx-auto p-2 sm:p-6 border-2 border-c-black-90 rounded-md sm:rounded-lg">
          <div className="max-w-lg mx-auto mt-4">
            <p className="text-center benton-bold text-2xl">Crear nueva sala</p>
            <div className="w-full flex flex-wrap items-center">
              <div className="flex items-center mx-2 w-full">
                <p className="mx-2 text-lg font-bold">Número de cartas:</p>
                <select defaultValue={14} onChange={handleInput} name="max_cards" className={clsx("block font-bold text-center appearance-none bg-white border border-gray-300 hover:border-gray-400 p-1 my-2 focus:shadow leading-tight focus:outline-none")}>
                  <option value="4">4</option>
                  <option value="6">6</option>
                  <option value="12">12</option>
                  <option value="14">14</option>
                  <option value="20">20 [Fijas]</option>
                  <option value="24">24 [Fijas]</option>
                </select>
              </div>
              <div className="flex items-center mx-2 w-full">
                <p className="mx-2 text-lg font-bold">Expira en:</p>
                <select defaultValue={3} onChange={handleInput} name="expires_at" className="block font-bold text-center appearance-none bg-white border border-gray-300 hover:border-gray-400 p-1 my-2 focus:shadow leading-tight focus:outline-none">
                  <option>2</option>
                  <option>3</option>
                  <option>7</option>
                  <option>15</option>
                  <option>30</option>
                  <option>60</option>
                  <option>90</option>
                  <option>365</option>
                </select>
                <p className="mx-2 text-lg font-bold">días.</p>
              </div>
              <div className="flex items-center mx-2 w-full">
                <p className="mx-2 text-lg font-bold whitespace-no-wrap">Nombre de sala:</p>
                <input onKeyPress={handleCreateKeyPress} value={formData.room_name} name="room_name" onChange={handleInput} className="w-40 bg-white my-2 focus:outline-none focus:shadow-outline border border-gray-300 py-1 px-4 appearance-none" type="text" placeholder="Mi sala" />
              </div>
              { state.error && <p className="text-c-cards-red text-sm text-center w-full">{ state.error }</p> }
              <button disabled={!formData.room_name} onClick={createRoom} className={clsx('border-2 border-c-pink rounded-md px-6 py-1 text-xl sm:text-2xl font-bold mx-auto my-8', formData.room_name ? 'cursor-pointer' : 'opacity-25')}>Crear</button>
            </div>
          </div>

          { lastRoom.id && (<>
            <p className="text-center benton-bold mt-2 text-2xl">Nueva sala creada</p>
            <table className="table-fixed mx-auto w-full max-w-4xl bg-white mt-2 mb-4">
              <thead>
                <tr>
                  <th className="break-all w-3/12 py-1 sm:text-2xl border-r-4 border-l-4 border-white font-bold bg-c-pink text-white text-center">Nombre</th>
                  <th className="break-all w-3/12 py-1 sm:text-2xl border-r-4 border-l-4 border-white font-bold bg-c-pink text-white">Expira</th>
                  <th className="break-all w-6/12 py-1 sm:text-2xl border-r-4 border-l-4 border-white font-bold bg-c-pink text-white">Enlace</th>
                </tr>
              </thead>
              <tbody>
                <tr key={lastRoom.id}>
                  <td className="break-all sm:break-normal border-4 border-white px-px sm:px-4 py-1 sm:py-2 sm:text-lg text-center">{lastRoom.name}</td>
                  <td className="break-all border-4 border-white px-px sm:px-4 py-1 sm:py-2 sm:text-lg text-center">{moment(lastRoom.expires_at).isBefore(moment()) ? 'Expirada' : moment().to(lastRoom.expires_at).charAt(0).toUpperCase() + moment().to(lastRoom.expires_at).slice(1) + '.'}</td>

                  <td className="border-4 border-white px-px sm:px-2 py-1 sm:text-lg text-center">
                    { moment(lastRoom.expires_at).isAfter(moment()) && <>
                      <CopyToClipboard onCopy={(i) => setLastCopy(i)} text={`${process.env.REACT_APP_URL}/${lastRoom.id}`}>
                        <button className={clsx('rounded border mx-2 my-1 px-2 py-1 text-sm font-bold bg-white', lastCopy === `${process.env.REACT_APP_URL}/${lastRoom.id}` ? 'text-green-500 border-green-500 hover:text-white hover:bg-green-500' : 'border-c-pink hover:text-white hover:bg-c-pink')}>Copiar</button>
                      </CopyToClipboard>
                      <button onClick={() => history.push(`/${lastRoom.id}`)} className="rounded border mx-2 my-1 px-2 py-1 text-sm font-bold border-c-pink hover:text-white hover:bg-c-pink">Acceder</button>
                    </> }
                  </td>
                </tr>
              </tbody>
            </table>
          </>)}

          <p className="text-center benton-bold mt-2 text-2xl">Mis salas ({user.username})</p>
          <table className="table-fixed mx-auto mt-2 w-full max-w-4xl">
            <thead>
              <tr>
                <th className="break-all w-2/12 py-1 sm:text-2xl border-r-4 border-l-4 border-white font-bold bg-c-pink text-white text-center">Nombre</th>
                <th className="break-all w-3/12 py-1 sm:text-2xl border-r-4 border-l-4 border-white font-bold bg-c-pink text-white">Expira</th>
                <th className="break-all w-6/12 py-1 sm:text-2xl border-r-4 border-l-4 border-white font-bold bg-c-pink text-white">Enlace</th>
                <th className="break-all w-1/12 py-1 sm:text-2xl border-l-4 border-white bg-c-pink"></th>
              </tr>
            </thead>
            <tbody>
              { rooms.length === 0 && <tr><td colSpan="4" className="py-1 text-center">No hay salas creadas</td></tr> }
              { rooms.map((room) => {
                return (
                  <tr key={room.id}>
                    <td className="break-all sm:break-normal border-4 border-white px-px sm:px-4 py-1 sm:py-2 sm:text-lg text-center">{room.name}</td>
                    <td className="break-all border-4 border-white px-px sm:px-4 py-1 sm:py-2 sm:text-lg text-center">{moment(room.expires_at).isBefore(moment()) ? 'Expirada' : 'En ' + moment(room.expires_at).diff(moment(), 'days') + ' días'}</td>

                    <td className="border-4 border-white px-px sm:px-2 py-1 sm:text-lg text-center">
                      { (moment(room.expires_at).isAfter(moment()) || ['comunidad', 'test'].includes(user.username)) && <>
                        <CopyToClipboard onCopy={(i) => setLastCopy(i)} text={`${process.env.REACT_APP_URL}/${room.id}`}>
                          <button className={clsx('rounded border mx-2 my-1 px-2 py-1 text-sm font-bold bg-white', lastCopy === `${process.env.REACT_APP_URL}/${room.id}` ? 'text-green-500 border-green-500 hover:text-white hover:bg-green-500' : 'border-c-pink hover:text-white hover:bg-c-pink')}>Copiar</button>
                        </CopyToClipboard>
                        <button onClick={() => history.push(`/${room.id}`)} className="rounded border mx-2 my-1 px-2 py-1 text-sm font-bold border-c-pink hover:text-white hover:bg-c-pink">Acceder</button>
                      </> }
                    </td>

                    <td className="border-l-4 border-t-4 border-b-4 border-white px-px py-1 sm:py-2 sm:text-lg text-center text-red-500 font-black">
                      <img alt="x" src={images.x} className="p-1 h-6 w-6 mx-auto cursor-pointer" onClick={() => deleteRoom(room)}/>
                    </td>
                  </tr>
                )
              }) }
            </tbody>
          </table>
        </div>
        <div className="mb-24"></div>
      </div>
    </div>
  )
}
