import carta_1 from './images/cards/carta_1.jpg'
import carta_2 from './images/cards/carta_2.jpg'
import carta_3 from './images/cards/carta_3.jpg'
import carta_4 from './images/cards/carta_4.jpg'
import carta_5 from './images/cards/carta_5.jpg'
import carta_6 from './images/cards/carta_6.jpg'
import carta_7 from './images/cards/carta_7.jpg'
import carta_8 from './images/cards/carta_8.jpg'
import carta_9 from './images/cards/carta_9.jpg'
import carta_10 from './images/cards/carta_10.jpg'
import carta_11 from './images/cards/carta_11.jpg'
import carta_12 from './images/cards/carta_12.jpg'
import carta_13 from './images/cards/carta_13.jpg'
import carta_14 from './images/cards/carta_14.jpg'
import carta_15 from './images/cards/carta_15.jpg'
import carta_16 from './images/cards/carta_16.jpg'
import carta_17 from './images/cards/carta_17.jpg'
import carta_18 from './images/cards/carta_18.jpg'
import carta_19 from './images/cards/carta_19.jpg'
import carta_20 from './images/cards/carta_20.jpg'
import carta_21 from './images/cards/carta_21.jpg'
import carta_22 from './images/cards/carta_22.jpg'
import carta_23 from './images/cards/carta_23.jpg'
import carta_24 from './images/cards/carta_24.jpg'
import carta_25 from './images/cards/carta_25.jpg'
import carta_26 from './images/cards/carta_26.jpg'
import carta_27 from './images/cards/carta_27.jpg'
import carta_28 from './images/cards/carta_28.jpg'
import carta_29 from './images/cards/carta_29.jpg'
import carta_30 from './images/cards/carta_30.jpg'
import carta_31 from './images/cards/carta_31.jpg'
import carta_32 from './images/cards/carta_32.jpg'
import carta_33 from './images/cards/carta_33.jpg'
import carta_34 from './images/cards/carta_34.jpg'
import carta_35 from './images/cards/carta_35.jpg'
import carta_36 from './images/cards/carta_36.jpg'
import carta_37 from './images/cards/carta_37.jpg'
import carta_38 from './images/cards/carta_38.jpg'
import carta_39 from './images/cards/carta_39.jpg'
import carta_40 from './images/cards/carta_40.jpg'
import carta_41 from './images/cards/carta_41.jpg'
import carta_42 from './images/cards/carta_42.jpg'
import carta_43 from './images/cards/carta_43.jpg'
import carta_44 from './images/cards/carta_44.jpg'
import carta_45 from './images/cards/carta_45.jpg'
import carta_46 from './images/cards/carta_46.jpg'
import carta_47 from './images/cards/carta_47.jpg'
import carta_48 from './images/cards/carta_48.jpg'
import carta_49 from './images/cards/carta_49.jpg'
import carta_50 from './images/cards/carta_50.jpg'
import carta_51 from './images/cards/carta_51.jpg'
import carta_52 from './images/cards/carta_52.jpg'
import carta_53 from './images/cards/carta_53.jpg'
import carta_54 from './images/cards/carta_54.jpg'
import carta_55 from './images/cards/carta_55.jpg'
import carta_56 from './images/cards/carta_56.jpg'
import carta_57 from './images/cards/carta_57.jpg'
import carta_58 from './images/cards/carta_58.jpg'
import carta_59 from './images/cards/carta_59.jpg'
import carta_60 from './images/cards/carta_60.jpg'
import carta_61 from './images/cards/carta_61.jpg'
import carta_62 from './images/cards/carta_62.jpg'
import carta_63 from './images/cards/carta_63.jpg'
import carta_64 from './images/cards/carta_64.jpg'
import carta_65 from './images/cards/carta_65.jpg'
import carta_66 from './images/cards/carta_66.jpg'
import carta_67 from './images/cards/carta_67.jpg'
import carta_68 from './images/cards/carta_68.jpg'
import carta_69 from './images/cards/carta_69.jpg'
import carta_70 from './images/cards/carta_70.jpg'
import carta_71 from './images/cards/carta_71.jpg'
import carta_72 from './images/cards/carta_72.jpg'
import carta_73 from './images/cards/carta_73.jpg'
import carta_74 from './images/cards/carta_74.jpg'
import carta_75 from './images/cards/carta_75.jpg'
import carta_76 from './images/cards/carta_76.jpg'
import carta_77 from './images/cards/carta_77.jpg'
import carta_78 from './images/cards/carta_78.jpg'
import carta_79 from './images/cards/carta_79.jpg'
import carta_80 from './images/cards/carta_80.jpg'
import carta_81 from './images/cards/carta_81.jpg'
import carta_82 from './images/cards/carta_82.jpg'
import carta_83 from './images/cards/carta_83.jpg'
import carta_84 from './images/cards/carta_84.jpg'
import carta_85 from './images/cards/carta_85.jpg'
import carta_86 from './images/cards/carta_86.jpg'
import carta_87 from './images/cards/carta_87.jpg'
import carta_88 from './images/cards/carta_88.jpg'
import carta_89 from './images/cards/carta_89.jpg'
import carta_90 from './images/cards/carta_90.jpg'
import carta_91 from './images/cards/carta_91.jpg'
import carta_92 from './images/cards/carta_92.jpg'
import carta_93 from './images/cards/carta_93.jpg'
import carta_94 from './images/cards/carta_94.jpg'
import carta_95 from './images/cards/carta_95.jpg'
import carta_96 from './images/cards/carta_96.jpg'
import back from './images/cards/back.png'

import bottomLeft from './images/bottom-left.svg'
import logo from './images/logo.svg'
import playButton from './images/play-button.png'
import cardsStack from './images/cards-stack.png'
import topRight from './images/top-right.svg'
import logoVertical from './images/logo-vertical.png'
import cardSize from './images/card-size.svg'
import showColors from './images/show-colors.svg'
import showTime from './images/show-time.svg'
import showNotes from './images/show-notes.svg'
import shuffleCards from './images/shuffle-cards.svg'
import turnAround from './images/turn-around.svg'
import cardsScreen from './images/cards-screen.svg'
import fourCreativities from './images/four-creativities.svg'
import instituto8 from './images/instituto8.svg'
import tapete01 from './images/tapete01.svg'

import bgAprendiz from './images/bg-aprendiz.svg'
import bgArtesana from './images/bg-artesana.svg'
import bgReina from './images/bg-reina.svg'
import bgSabio from './images/bg-sabio.svg'
import circleAprendiz from './images/circle-aprendiz.svg'
import circleArtesana from './images/circle-artesana.svg'
import circleReina from './images/circle-reina.svg'
import circleSabio from './images/circle-sabio.svg'
import fourCreativitiesArrows from './images/four-creativities-arrows.svg'
import x from './images/x.svg'
import question from './images/question.svg'
import postItBackground from './images/post-it-bg.svg'

import iconTapete from './images/icon-tapete.svg'

const assets = {
  images: { postItBackground, x, question, fourCreativitiesArrows, bgAprendiz, bgArtesana, bgReina, bgSabio, circleAprendiz, circleArtesana, circleReina, circleSabio, showNotes, bottomLeft, logo, playButton, cardsStack, topRight, logoVertical, cardSize, showColors, showTime, shuffleCards, turnAround, cardsScreen, fourCreativities, instituto8, iconTapete, tapete01 },
  cards: [
    {
      id: 1,
      image: carta_1,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 2,
      image: carta_2,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 3,
      image: carta_3,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 4,
      image: carta_4,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 5,
      image: carta_5,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 6,
      image: carta_6,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 7,
      image: carta_7,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 8,
      image: carta_8,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 9,
      image: carta_9,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 10,
      image: carta_10,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 11,
      image: carta_11,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 12,
      image: carta_12,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 13,
      image: carta_13,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 14,
      image: carta_14,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 15,
      image: carta_15,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 16,
      image: carta_16,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'pink',
        secondaries: ['blue', 'green'],
      },
    }, {
      id: 17,
      image: carta_17,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 18,
      image: carta_18,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 19,
      image: carta_19,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 20,
      image: carta_20,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 21,
      image: carta_21,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 22,
      image: carta_22,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 23,
      image: carta_23,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 24,
      image: carta_24,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 25,
      image: carta_25,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 26,
      image: carta_26,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 27,
      image: carta_27,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 28,
      image: carta_28,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 29,
      image: carta_29,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 30,
      image: carta_30,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 31,
      image: carta_31,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 32,
      image: carta_32,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'light-blue',
        secondaries: ['pink', 'blue'],
      },
    }, {
      id: 33,
      image: carta_33,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 34,
      image: carta_34,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 35,
      image: carta_35,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 36,
      image: carta_36,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 37,
      image: carta_37,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 38,
      image: carta_38,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 39,
      image: carta_39,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 40,
      image: carta_40,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 41,
      image: carta_41,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 42,
      image: carta_42,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 43,
      image: carta_43,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 44,
      image: carta_44,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 45,
      image: carta_45,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 46,
      image: carta_46,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 47,
      image: carta_47,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 48,
      image: carta_48,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'yellow',
        secondaries: ['light-blue', 'red'],
      },
    }, {
      id: 49,
      image: carta_49,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 50,
      image: carta_50,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 51,
      image: carta_51,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 52,
      image: carta_52,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 53,
      image: carta_53,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 54,
      image: carta_54,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 55,
      image: carta_55,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 56,
      image: carta_56,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 57,
      image: carta_57,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 58,
      image: carta_58,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 59,
      image: carta_59,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 60,
      image: carta_60,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 61,
      image: carta_61,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 62,
      image: carta_62,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 63,
      image: carta_63,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 64,
      image: carta_64,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'blue',
        secondaries: ['pink', 'yellow'],
      },
    }, {
      id: 65,
      image: carta_65,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 66,
      image: carta_66,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 67,
      image: carta_67,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 68,
      image: carta_68,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 69,
      image: carta_69,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 70,
      image: carta_70,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 71,
      image: carta_71,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 72,
      image: carta_72,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 73,
      image: carta_73,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 74,
      image: carta_74,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 75,
      image: carta_75,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 76,
      image: carta_76,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 77,
      image: carta_77,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 78,
      image: carta_78,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 79,
      image: carta_79,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 80,
      image: carta_80,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'green',
        secondaries: ['yellow', 'red'],
      },
    }, {
      id: 81,
      image: carta_81,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 82,
      image: carta_82,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 83,
      image: carta_83,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 84,
      image: carta_84,
      back: back,
      thumb: null,
      type: 'sabio',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 85,
      image: carta_85,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 86,
      image: carta_86,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 87,
      image: carta_87,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 88,
      image: carta_88,
      back: back,
      thumb: null,
      type: 'artesana',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 89,
      image: carta_89,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 90,
      image: carta_90,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 91,
      image: carta_91,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 92,
      image: carta_92,
      back: back,
      thumb: null,
      type: 'aprendiz',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 93,
      image: carta_93,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 94,
      image: carta_94,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 95,
      image: carta_95,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    }, {
      id: 96,
      image: carta_96,
      back: back,
      thumb: null,
      type: 'reina',
      colors: {
        primary: 'red',
        secondaries: ['green', 'blue'],
      },
    },
  ],
}

export const images = assets.images
export const cards = assets.cards

export default assets




