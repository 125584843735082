import { covisageConstants } from '../_constants'

const flipStack = (value) => {
    return { type: covisageConstants.FLIP_STACK, value }
}

const flipCard = (card) => {
    return { type: covisageConstants.FLIP_CARD, card }
}

const addCard = (card, index = 0) => {
    return { type: covisageConstants.ADD_CARD, card, index }
}

const setMaxCards = (number) => {
    return { type: covisageConstants.MAX_CARDS, number }
}

const deleteCard = (card) => {
    return { type: covisageConstants.DELETE_CARD, card }
}

const setSelectedCards = (cards) => {
    return { type: covisageConstants.STATE_CHANGED, data: { selectedCards: cards } }
}

const setSecondaryBoardNotes = (notes) => {
    return { type: covisageConstants.STATE_CHANGED, data: { secondaryBoardNotes: notes } }
}

const setCardsFilter = (filter) => {
    return { type: covisageConstants.SET_CARDS_FILTER, filter }
}

const shuffleCards = () => {
    return { type: covisageConstants.SHUFFLE_CARDS }
}

const setCardNote = (id, value) => {
    return { type: covisageConstants.SET_CARD_NOTE, ...{ id, value } }
}

const setSlider = (color, value) => {
    return { type: covisageConstants.SET_SLIDER, ...{ color, value } }
}

const setRooms = (rooms) => {
    return { type: covisageConstants.SET_ROOMS, rooms }
}

const setUser = (user) => {
    return { type: covisageConstants.SET_USER, user }
}

export const covisageActions = {
    flipStack,
    flipCard,
    shuffleCards,
    addCard,
    deleteCard,
    setSelectedCards,
    setSecondaryBoardNotes,
    setCardsFilter,
    setMaxCards,
    setCardNote,
    setSlider,
    setRooms,
    setUser,
}
