import React, { useState } from 'react'
import clsx from 'clsx'
import { images } from './assets'
import moment from 'moment'
import EasyEdit from 'react-easy-edit';
import { Check, X } from 'react-feather'

export default ({ history }) => {
  const [password, setPassword] = useState('')
  const [state, setState] = useState({ users: [], showTable: false, loading: false })
  const [error, setError] = useState('')
  const formDataInitialState = {username: '', password: '', expires_after_days: '90', notes: ''}
  const [formData, setFormData] = useState(formDataInitialState)

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value})
  }

  const addDisabled = !formData.username || formData.username.length < 3 || !formData.password || formData.password.length < 3

  const getUsers = () => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}?action=get_users&password=${password}`, { method: 'GET' }).then((r) => {
      if (r.status === 403) {
        setError('Clave incorrecta')
        return setTimeout(() => setError(''), 3000)
      }
      r.json().then((d) => {
        setState({ users: d.users, showTable: true })
      })
    }).catch((r) => { console.log(r) })
  }

  const addUser = () => {
    const body = JSON.stringify({ username: formData.username, password: formData.password, expires_after_days: formData.expires_after_days, notes: formData.notes })

    return fetch(`${process.env.REACT_APP_BACKEND_URL}?action=add_user&password=${password}`, { method: 'POST', body }).then((r) => {
      r.json().then((d) => {
        setState({ users: d.users, showTable: true })
        setFormData(formDataInitialState)
      })
    })
  }

  const deleteUser = (username) => {
    const body = JSON.stringify({ username })

    return fetch(`${process.env.REACT_APP_BACKEND_URL}?action=delete_user&password=${password}`, { method: 'POST', body }).then((r) => {
      r.json().then((d) => {
        setState({ users: d.users, showTable: true })
      })
    })
  }

  const handlePasswordInput = (e) => setPassword(e.target.value)

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && password) {
      getUsers()
    }
  }

  const updateField = (username, fieldName, newValue) => {
    const body = JSON.stringify({ username, [fieldName]: newValue })

    setState(state => ({ ...state, loading: true }))

    return fetch(`${process.env.REACT_APP_BACKEND_URL}?action=update_user&password=${password}`, { method: 'POST', body }).then((r) => {
      setState(state => ({ ...state, loading: false }))
      r.json().then((d) => {
        setState({ users: d.users, showTable: true })
      })
    })
  }

  const validateCreatedAt = (value, user) => {
    try {
      moment(value).toISOString()
    } catch (e) {
      return false
    }

    const diff = moment().diff(moment(value))

    if (diff <= 0) {
      return false
    }

    return true
  }

  const validateExpiresAt = (value, user) => {
    try {
      moment(value).toISOString()
    } catch (e) {
      console.log(e)
      return false
    }

    const diff = moment(value).diff(moment(user.created_at))

    if (diff <= 0) {
      return false
    }

    return true
  }

  return (
    <div className="coach min-h-screen bg-c-bg py-4 flex items-center justify-center">
      <div className="w-full px-4">
        <div className="w-full max-w-lg mx-auto">
          <img alt="logo" src={images.logo} className="mx-auto px-4" onClick={() => history.push('/')} />
        </div>
        { state.showTable ? <>
          <table className="table-fixed mx-auto mt-12 w-full bg-c-bg1" style={{ maxWidth: '80rem'}}>
            <thead>
              <tr>
                <th className="w-1/12 py-1 sm:text-2xl border-r-8 border-l-8 border-c-bg font-bold bg-c-pink text-white text-center">Nº</th>
                <th className="w-4/12 py-1 sm:text-2xl border-r-8 border-l-8 border-c-bg font-bold bg-c-pink text-white">Usuario</th>
                <th className="w-3/12 py-1 sm:text-2xl border-r-8 border-l-8 border-c-bg font-bold bg-c-pink text-white">Inicio</th>
                <th className="w-3/12 py-1 sm:text-2xl border-r-8 border-l-8 border-c-bg font-bold bg-c-pink text-white">Fin</th>
                <th className="w-4/12 py-1 sm:text-2xl border-r-8 border-l-8 border-c-bg font-bold bg-c-pink text-white">Notas</th>
                <th className="w-4/12 py-1 sm:text-2xl border-r-8 border-l-8 border-c-bg font-bold bg-c-pink text-white">Contraseña</th>
                <th className="w-1/12 py-1 sm:text-2xl border-l-8 border-c-bg font-bold bg-c-pink text-white text-center"></th>
              </tr>
            </thead>
            <tbody>
              { !state.loading && state.users.map((i, index) => { return (
              <tr key={index}>
                <td className="border-r-8 border-l-8 border-t-4 border-b-2 border-c-bg px-1 sm:px-2 py-1 sm:py-2 sm:text-lg text-center">{index + 1}</td>
                <td className="border-r-8 border-l-8 border-t-4 border-b-2 border-c-bg px-1 sm:px-2 py-1 sm:py-2 sm:text-lg break-all">{i.username}</td>
                <td className="border-r-8 border-l-8 border-t-4 border-b-2 border-c-bg px-1 sm:px-2 py-1 sm:py-2 sm:text-lg capitalize">
                  <EasyEdit
                    className="flex"
                    type="date"
                    onSave={(value) => updateField(i.username, 'created_at', moment(value).toISOString())}
                    onCancel={() => {}}
                    saveButtonLabel={<Check className="w-4 h-4 text-green-500 mt-1" />}
                    cancelButtonLabel={<X className="w-4 h-4 text-red-500 mt-1" />}
                    value={i.created_at ? moment(i.created_at).format("D MMM YYYY") : '-'}
                    attributes={{ max: moment().format('YYYY-MM-DD') }}
                    onValidate={(value) => validateCreatedAt(value, i)}
                    validationMessage="Fecha no válida"
                  />
                </td>
                <td className="border-r-8 border-l-8 border-t-4 border-b-2 border-c-bg px-1 sm:px-2 py-1 sm:py-2 sm:text-lg capitalize">
                  <EasyEdit
                    className="flex"
                    type="date"
                    onSave={(value) => updateField(i.username, 'expires_at', moment(value).toISOString())}
                    onCancel={() => {}}
                    saveButtonLabel={<Check className="w-4 h-4 text-green-500 mt-1" />}
                    cancelButtonLabel={<X className="w-4 h-4 text-red-500 mt-1" />}
                    attributes={{ min: i.created_at ? moment(i.created_at).format('YYYY-MM-DD') : null }}
                    onValidate={(value) => validateExpiresAt(value, i)}
                    value={i.expires_at ? moment(i.expires_at).format("D MMM YYYY") : '-'}
                    validationMessage="Fecha no válida"
                  />
                </td>
                <td className="border-r-8 border-l-8 border-t-4 border-b-2 border-c-bg px-1 sm:px-2 py-1 sm:py-2 sm:text-lg break-all">
                  <EasyEdit
                    className="flex"
                    type="textarea"
                    onSave={(value) => updateField(i.username, 'notes', value)}
                    onCancel={() => {}}
                    saveButtonLabel={<Check className="w-4 h-4 text-green-500 mt-1" />}
                    cancelButtonLabel={<X className="w-4 h-4 text-red-500 mt-1" />}
                    value={i.notes ? i.notes : '-'}
                  />
                </td>
                <td className="border-r-8 border-l-8 border-t-4 border-b-2 border-c-bg px-1 sm:px-2 py-1 sm:py-2 sm:text-lg">
                  <EasyEdit
                    className="flex"
                    type="text"
                    onSave={(value) => updateField(i.username, 'password', value)}
                    onCancel={() => {}}
                    saveButtonLabel={<Check className="w-4 h-4 text-green-500 mt-1" />}
                    cancelButtonLabel={<X className="w-4 h-4 text-red-500 mt-1" />}
                    value={i.password}
                  />
                </td>
                <td className="border-l-8 border-t-4 border-b-2 border-c-bg px-4 py-1 sm:py-2 sm:text-lg text-center text-red-500 font-black cursor-pointer" onClick={() => window.confirm('Borrar usuario?') && deleteUser(i.username)}>
                  <img alt="x" src={images.x} style={{ minWidth: 16 }} className="p-1 mx-auto" />
                </td>
              </tr>
              )})}
            </tbody>
          </table>
          <div className="mx-auto px-1 mt-4 flex justify-between" style={{ maxWidth: '80rem'}}>
            <input value={formData.username} onChange={handleInput} name="username" minLength="3" placeholder="Usuario" className="w-24 sm:w-48 sm:text-2xl placeholder-opacity-25 px-2 font-bold bwhite mx-1" />
            <input value={formData.password} onChange={handleInput} name="password" minLength="3" placeholder="Password" className="w-24 sm:w-48 sm:text-2xl placeholder-opacity-25 px-2 font-bold bwhite mx-1" />
            <input value={formData.notes} onChange={handleInput} name="notes" minLength="3" maxLength="128" placeholder="Notas" className="w-24 sm:w-48 sm:text-2xl placeholder-opacity-25 px-2 font-bold bwhite mx-1" />

            <div className="flex items-center mx-2 w-full">
              <p className="mx-2 text-lg font-bold">Expira en:</p>
              <select defaultValue={90} onChange={handleInput} name="expires_after_days" className="block font-bold text-center appearance-none bg-white border border-gray-300 hover:border-gray-400 p-1 my-2 focus:shadow leading-tight focus:outline-none">
                <option>30</option>
                <option>90</option>
                <option>365</option>
              </select>
              <p className="mx-2 text-lg font-bold">días.</p>
            </div>
            <button onClick={addUser} disabled={addDisabled} className={clsx('w-36 sm:text-2xl font-bold bg-c-pink text-white text-center ml-1 px-6 py-1', addDisabled && 'opacity-25')}>Agregar</button>
          </div>
        </>
        : <div className="max-w-md mx-auto">
            <div className="mt-24"></div>
            <p className="text-3xl text-center benton-bold">Acceso Admin</p>
            <input onKeyPress={handleKeyPress} name="password" onChange={handlePasswordInput} className="bg-white my-2 focus:outline-none focus:shadow-outline border border-gray-300 py-2 px-4 block w-full appearance-none" type="password" placeholder="*********" />
            { error && <p className="text-c-cards-red text-sm text-center">{ error }</p> }
            <div className="w-full flex">
              <button onClick={getUsers} disabled={!password} className={clsx('bg-c-pink text-white px-6 py-1 text-2xl font-bold ml-auto mr-0 my-2', !password && 'opacity-25')}>Acceder</button>
            </div>
            <div className="mb-24"></div>
          </div>
        }
      </div>
    </div>
  )
}
